.rc-tooltip {
  opacity: 1 !important;
  box-shadow: 0 2px 8px 0 rgba(42, 60, 67, 0.15);
}

.rc-tooltip .rc-tooltip-arrow {
  border-right-color: #c6d6d0 !important;
}

.rc-tooltip .rc-tooltip-inner {
  opacity: 1;
  border: none;
  color: #809684;
  background: #eef9f4;
}

.text-right {
  text-align: right;
}

.text-gray {
  color: var(--g-color-dark-gray);
}

.margin-top-0 {
  margin-top: 0;
}

.margin-bottom-0 {
  margin-bottom: 0;
}

.margin-top-20 {
  margin-top: 20px;
}
