.rc-tooltip {
  opacity: 1 !important;
  box-shadow: 0 2px 8px 0 rgba(42, 60, 67, 0.15);
}

.rc-tooltip .rc-tooltip-arrow {
  border-right-color: #c6d6d0 !important;
}

.rc-tooltip .rc-tooltip-inner {
  opacity: 1;
  border: none;
  color: #809684;
  background: #eef9f4;
}

.text-right {
  text-align: right;
}

.text-gray {
  color: var(--g-color-dark-gray);
}

.margin-top-0 {
  margin-top: 0;
}

.margin-bottom-0 {
  margin-bottom: 0;
}

.margin-top-20 {
  margin-top: 20px;
}

.toast {
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-height: 4em;
  padding: 6px;
  color: var(--g-color-dark);
}

.toast__body {
  width: 100%;
}

.toast__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.toast__icon {
  margin-right: 6px;
  font-size: 56px;
  text-align: center;
}

.toast--success .toast__icon {
  font-size: 25px;
}

.toast__title {
  margin: 0;
  padding: 0;
  font: var(--g-font-big);
  font-size: 14px;
}

.toast__message {
  margin: 0.5em 0 0;

  /* color: var(--g-color-gray-dark, #616a78); */
  font: var(--g-font-small);
  font-size: 12px;
}

.toast__close-button {
  align-self: center;
  color: var(--g-color-dark-gray);
  font-size: 0.7em;
  margin-right: 6px;
}

.toast.toast--warning {
  border-left: 0.25em solid var(--g-color-warning);
  background-image: linear-gradient(to right, #fff1e6, #fff1e6);
}

.toast--warning .toast__icon,
.toast--warning .toast__title {
  color: var(--g-color-warning);
}

.toast.toast--error {
  border-left: 0.25em solid var(--g-color-red);
  background-image: linear-gradient(to right, #ffe5e5, #fff4f4);
}

.toast--error .toast__icon,
.toast--error .toast__title {
  color: var(--g-color-red);
}

.toast.toast--info {
  border-left: 0.25em solid var(--g-color-blue);
  background-image: linear-gradient(to right, #def2ff, #f1faff);
}

.toast--info .toast__icon,
.toast--info .toast__title {
  color: var(--g-color-blue);
}

.toast.toast--success {
  border-left: 0.25em solid var(--g-color-green);
  background-image: linear-gradient(to right, #e2ffea, #f3fff6);
}

.toast--success .toast__icon,
.toast--success .toast__title {
  color: var(--g-color-green);
}

.toast.toast--primary {
  border-left: 0.25em solid #f1faff;
  border-left: 0.25em solid var(--g-color-primary, #f1faff);
  background-image: linear-gradient(
    to right,
    #def2ff,
    #f1faff);
  background-image: linear-gradient(
    to right,
    var(--g-color-primary, #def2ff),
    var(--g-color-primary, #f1faff)
  );
}

.toast--primary .toast__icon {
  color: var(--g-color-white);
}

.toast--primary .toast__message {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: var(--g-color-white);
}

.toast--primary .toast-content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.toast--primary .toast-content .toast-content--before {
  margin-right: 10px;
}

